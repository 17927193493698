const app = {
  state: {
    sidebarCollapse: true
  },

  mutations: {
    SET_SIDEBAR_COLLAPSE: (state, payload) => {
      state.sidebarCollapse = payload
    }
  },

  actions: {
    toggleSidebarCollapse ({ commit, state }) {
      commit('SET_SIDEBAR_COLLAPSE', !state.sidebarCollapse)
    }
  }
}

export default app
