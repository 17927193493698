import { loginByUsername, Logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { setItem, getItem } from '@/utils/zenLocalStorage'

const user = {
  state: {
    user: getItem('user'),
    token: getToken()
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      user.user_initial = user.name.split('')[0] + user.name.split('')[1]
      state.user = user
      setItem('user', user)
    }
  },

  actions: {
    LoginByUsername ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        loginByUsername(username, userInfo.password).then(response => {
          commit('SET_TOKEN', {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token
          })
          setToken({
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token
          })
          commit('SET_USER', response.data.user)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        Logout(state.token).then(() => {
          commit('SET_TOKEN', null)
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
