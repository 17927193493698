<template>
  <v-main>
    <v-container class="fill-height grey lighten-5 mb-6" fluid >
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card raised height="488" width="384" class="pt-12" style="border-radius: 16px;">
                    <div class="text-center">
                      <v-avatar tile height="62" width="62">
                        <v-icon size="62">$vuetify.icons.zenwel_admin</v-icon>
                      </v-avatar>
                      <span class="headline">Zenwel</span>
                      <span class="headline font-weight-bold"> Admin</span>
                    </div>
                    <v-card-text class="mt-10">
                        <v-form>
                          <div class="mb-2">
                            <span class="mt-4">Username/email address</span>
                          </div>
                          <v-text-field
                            v-model="data.username"
                            outlined
                            dense
                            placeholder="ex: andri@gmail.com"
                          ></v-text-field>
                          <div class="mb-2">
                            <span class="mt-4">Password</span>
                          </div>
                          <v-text-field
                            v-model="data.password"
                            id="password"
                            outlined
                            dense
                            placeholder="Password"
                            name="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required]"
                            :type="show1 ? 'text' : 'password'"
                              @click:append="show1 = !show1"/>

                          <!-- <v-checkbox v-model="remember" label="Remember me" class="mt-n3"></v-checkbox> -->
                          <div class="text-center" style="width:100%">
                              <v-btn block color="#00D79E" style="color: #FFFFFF" :loading="loading4" @click="getLogin">
                                  Login
                                  <template v-slot:loader>
                                    <span class="custom-loader">
                                    <v-icon>$vuetify.icons.loading</v-icon>
                                    </span>
                                  </template>
                              </v-btn>
                          </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="notif.notifyUpdate" :timeout="notif.timeoutNotify" multi-line top right :color="notif.colorNotify" >
          {{ notif.textNotify }}
          <v-btn color="white" text @click="notif.notifyUpdate = false">
            {{ lang.t('$vuetify.close') }}
          </v-btn>
        </v-snackbar>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: 'Login',

  data () {
    return {
      show1: false,
      rules: {
        required: value => !!value || 'Required.'
      },
      data: {
        password: '',
        username: ''
      },
      remember: false,
      loader: null,
      loading4: false,
      notif: {
        notifyUpdate: false,
        textNotify: '',
        timeoutNotify: 2000,
        colorNotify: ''
      }
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  },

  methods: {
    getLogin () {
      this.loader = 'loading4'
      this.$store.dispatch('LoginByUsername', this.data).then(() => {
        this.notif.notifyUpdate = true
        this.notif.textNotify = 'sukses'
        this.notif.colorNotify = 'success'
        this.loading = false
        this.$router.push('/')
      }).catch(error => {
        this.notif.notifyUpdate = true
        this.notif.textNotify = error
        this.notif.colorNotify = 'error'
        this.loading = false
      })
    }
  }
}
</script>
