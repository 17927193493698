import request from '@/utils/request'
// import store from '@/store'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function getSystemToken () {
  const data = {
    client_id: 2,
    client_secret: 'B8lqNO7x1qjlPZA3j4b7DXJXi6qMMSv3DsiWNasd',
    grant_type: 'refresh_token',
    refresh_token: 'def50200c56ee7fba0081daa5bcbe818bceeb2c87f94972d8230055a758606947043c772c2239edb432710a8b724acbd1a7b2beed2800f00b69446324f2b5d733f112e698520a8691006332b1503925167648c2ddb810dddd568a64fef4e203212190b6d374c11d3fb7afa1986eefb6b741c9593e3bd26620f352bf450475761a7223b494026b9891b9d443ac01c9e7610a6e243d947381cc91ad6261e169bfc3a7bd0fd186cdcc339ca0b24731d23b481b30b40257433e4d12cf9090a71208f665c46bdbc0cd42e9a1e54f558bb14e46fc93de6b64392131d1e17b5c9ad6249149625053b2b94e42a89f65b00fc214b0f5f9f6ad3c50e0d57f5795ea0ec2fe91f17dae9bfef1b8252032917cfb963b587032b2924a3346c704d245928de6fd4f8330c00bdf60ac4280928b3f76825642316c1c8a6b921d2c0b88e66fe904823504226ba17f5011dc1ad84e3f018068e1b62a6513542ec3cf89043e4df9144a797'
  }

  return request({
    // url: store.getters.language + '/oauth/clienttoken',
    url: langId + '/oauth/token',
    method: 'post',
    data
  })
}

export function loginByUsername (username, password) {
  const data = {
    username,
    password,
    client_id: 2,
    client_secret: 'B8lqNO7x1qjlPZA3j4b7DXJXi6qMMSv3DsiWNasd',
    grant_type: 'password'
  }
  return request({
    // url: store.getters.language + '/oauth/newtoken',
    url: langId + '/oauth/token',
    method: 'post',
    data
  })
}

export function Logout () {
  return request({
    url: langId + '/oauth/logout',
    method: 'get'
  })
}
