<template>
  <div>
      <div v-if="filter">
          <v-btn v-for="i in filterBtn" :key="i.id" :retain-focus-on-click="true" :color="i.color" rounded small depressed outlined class="mr-1" style="height: 24px; width: 60px" @click="dateFilter(i)">
            <span class="text-capitalize" :style="'color: ' + i.colorTxt + '; font-size: 10px'">{{ i.value }}</span>
          </v-btn>
      </div>
      <div v-else>
          <v-btn v-for="i in filterBtn1" :key="i.id" :retain-focus-on-click="true" :color="i.color" rounded small depressed outlined class="mr-1" style="height: 24px; width: 60px" @click="dateFilter1(i)">
            <span class="text-capitalize" :style="'color: ' + i.colorTxt + '; font-size: 10px'">{{ i.value }}</span>
          </v-btn>
      </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['filter'],
  computed: {
    date () {
      let date = [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').subtract(1095, 'd').format('YYYY-MM-DD')]
      return date
    }
  },
  data: () => ({
    filterBtn1: [
      {
        id: 1,
        value: '1 Month',
        date_start: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      },
      {
        id: 2,
        value: 'All Time',
        date_start: moment().subtract(6, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      }
    ],
    filterBtn: [
      {
        id: 1,
        value: '1 Week',
        date_start: moment().subtract(6, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      },
      {
        id: 2,
        value: '1 Month',
        date_start: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      },
      {
        id: 3,
        value: '1 Year',
        date_start: moment().subtract(365, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      },
      {
        id: 4,
        value: '3 Year',
        date_start: moment().subtract(1095, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      },
      {
        id: 5,
        value: '5 Year',
        date_start: moment().subtract(1825, 'd').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        color: '#E0E0E0',
        colorTxt: '#767676'
      }
    ]
  }),
  methods: {
    dateFilter (data) {
      this.filterBtn.map(i => {
        if (data.id === i.id) {
          i.color = '#00D79E'
          i.colorTxt = '#00D79E'
        } else {
          i.color = '#E0E0E0'
          i.colorTxt = '#767676'
        }
      })
      this.$emit('filter', data)
    },
    dateFilter1 (data) {
      this.filterBtn1.map(i => {
        if (data.id === i.id) {
          i.color = '#00D79E'
          i.colorTxt = '#00D79E'
        } else {
          i.color = '#E0E0E0'
          i.colorTxt = '#767676'
        }
      })
      this.$emit('filter', data)
    }
  }
}
</script>
