export default {
  dataTable: {
    ariaLabel: {
      sortNone: 'Sort None'
    }
  },

  add_admin: 'Tambah Admin',
  activity: 'Aktivitas',
  current_activity: 'Aktivitas Terbaru',
  see_all_activity: 'Lihat Semua Aktivitas',
  admin: 'Admin',
  categories: 'Kategori',
  download: 'Unduh',
  print: 'Cetak',
  add: 'Tambah',
  new_admin: 'Admin Baru',
  name: 'Nama',
  username: 'Username',
  email: 'Email',
  password: 'Sandi',
  phone_number: 'Nomor Telepon',
  level: 'Level',
  country: 'Negara',
  city: 'Kota',
  upload: 'Unggah',
  photo: 'Foto',
  save: 'Simpan',
  admin_name: 'Nama Admin',
  last_activity: 'Aktifitas Terakhir',
  action: 'Aksi',
  edit_admin: 'Edit Admin',
  total_activity: 'Total Aktifitas',
  joined_date: 'Tanggal Bergabung',
  created_by: 'Dibuat oleh',
  close: 'Tutup',
  update: 'Update',
  activities: 'Aktifitas',
  activity_detail: 'Detail Aktifitas',
  noDataText: 'Tidak Ada Text Data',
  over_view: 'Ikhtisar',
  create_newdeals: 'Buat Hot Deals Baru',
  service: 'Layanan',
  class: 'Kelas',
  service_class_voucher: 'Voucher Layanan dan Kelas',
  new_voucher: 'Voucher Baru',
  service_voucher: 'Voucher Layanan',
  class_voucher: 'Voucher Kelas',
  voucher: 'Voucher',
  voucher_used: 'Voucher Terpakai',
  merchant_name: 'Nama Merchant',
  item: 'Item',
  discount: 'Diskon',
  revenue: 'Pendapatan',
  step_1deals: 'Langkah 1: Pilih Merchant',
  step_2deals: 'Langkah 2: Pilih Kelas atau Layanan',
  step_3deals: 'Langkah 3: Detail Deals',
  search: 'Cari',
  next: 'Selanjutnya',
  change: 'Ganti',
  selected: 'Terpilih',
  minimum_purchase: 'Minimum Pembelian',
  voucher_limit: 'Batas Voucher',
  max_usage_customer: 'Maksimal Penggunaan Tiap Pelanggan',
  platform_applied: 'Diterapkan di platform',
  expiry_period: 'Periode Kadaluwarsa',
  day: 'Hari',
  priority_level: 'Tingkat Prioritas',
  location_applied: 'Diterapkan di Lokasi',
  create: 'Simpan',
  edit_hot_promo: 'Edit Hot Promo',
  discounted_price: 'Harga Setelah Diskon',
  normal_price: 'Harga Normal',
  date_period: 'Periode Tanggal',
  location: 'Lokasi',
  create_newecard: 'Buat e-Card Baru',
  merchant_with_ecard: 'Merchant yang menggunakan e-Card',
  ecard_used: 'e-Card Terpakai',
  no_activity: 'Tidak ada aktifitas',
  ecard_amount: 'Jumlah e-Card',
  expiry_date: 'Tanggal Kadaluarsa',
  step_2ecard: 'Langkah 2: Detail e-Card',
  other_ecard: 'e-Card lain',
  ecard_background: 'Warna Background e-Card',
  day_limit: 'Batas Hari',
  preview: 'Pratinjau',
  available_until: 'Tersedia Sampai',
  amount: 'Jumlah',
  price: 'Harga',
  date: 'Tanggal',
  edit: 'Ubah',
  ecard: 'e-Card',
  detail: 'Detail',
  delete: 'Hapus',
  delete_ecard: 'Hapus e-Card ini',
  create_newcashback: 'Buat Cashback Baru',
  merchant_with_cashback: 'Merchant yang menggunakan Cashback',
  cashback_used: 'Cashback Terpakai',
  cashback: 'Cashback',
  cashback_amount: 'Jumlah Cashback',
  step_2cashback: 'Langkah 2: Detail Cashback',
  cashback_limit: 'Batas Cashback',
  set_for_newmember: 'Tetapkan untuk Member baru',
  cashback_type: 'Tipe Cashback',
  set_for_first_order: 'Ditetapkan Untuk Pertama Kali Order',
  min_purchase: 'Min. Pembelian',
  are_you_sure_delete: 'Kamu yakin akan menghapus ',
  create_article: 'Buat Artikel Baru',
  articles: 'Artikel',
  new_articles: 'Artikel Baru',
  tags_categories: 'Tag/Kategori',
  basic_merchant: 'Basic Merchant',
  visitor: 'Pengunjung',
  from_indonesia: 'Dari Indonesia',
  outside_indonesia: 'Luar Indonesia',
  title: 'Judul',
  published_on: 'Diterbitkan Pada',
  tags: 'Tags',
  cancel: 'Batal',
  del_account: 'Akun ini dan semua aktifitasnya akan dihapus dari database',
  del_activity: 'Aktifitas ini akan dihapus dari akun dan database',
  total: 'Total',
  merchant: 'Merchant',
  new_merchant: 'Merchant Baru',
  merchant_list: 'Daftar Merchant',
  hourly_activity: 'Aktifitas tiap jam',
  staff: 'Karyawan',
  platform: 'Platform',
  time_joined: 'Tanggal Bergabung',
  inactive_merchant: 'Merchant Tidak aktif',
  top_longest_inactive: 'Tidak Aktif Terlama',
  merchant_activity: 'Aktifitas Merchant',
  member_status: 'Status Keanggotaan',
  del_article: 'Artikel ini akan dihapus dari database',
  article: 'Artikel',
  publish: 'Terbitkan',
  draft: 'Draft',
  last_modified: 'Terakhir Diubah',
  words: 'Kata',
  del_ecard: 'E-Card ini akan dihapus dari database',
  bussiness: 'Bisnis',
  package: 'Paket',
  subscription: 'Subscription',
  marketplace: 'Marketplace',
  biz_deal: 'Biz Deals',
  see_price_detail: 'Lihat Detail Harga',
  select: 'Pilih',
  payment_method: 'Metode pembayaran',
  input: 'Masukkan',
  reference_code: 'Kode Referensi',
  apply: 'Terapkan',
  payment: 'Pembayaran',
  cancel_transaction: 'Batalkan Transaksi',
  cancel_option: 'Opsi pembatalan',
  package_to_change: 'Paket untuk diubah',
  package_expired_on: 'Paket akan berakhir pada :',
  automatic_downgrade_note: 'Downgrade akan otomatis downgrade ke Free',
  merchant_will_get_refund: 'Merchant akan mendapatkan pengembalian :',
  business_type: 'Tipe Bisnis',
  your_package_by_extend: 'Paket anda akan di extend',
  company_city: 'Kota Perusahaan',
  owner_phone: 'Nomor Telepone Pemilik',
  marketplace_status: 'Status Marketplace',
  published: 'Diterbitkan',
  unpublished: 'Tidak diterbitkan',
  manage: 'Kelola',
  payment_history: 'Riwayat Pembayaran',
  downgrade_note: 'Anda akan downgrade paket. Perubahan Anda akan diaktifkan setelah',
  price_detail: 'Detil Harga'
}
