import Cookies from 'js-cookie'

const TokenKey = 'zenwell-internal-token'

const removeOnceLoggedOut = [
  'zen_locations_by_staff',
  'zen_selected_location',
  'zencal_config',
  'zencal_filter_staffs',
  'zencal_view_mode',
  'zencal_filter_staffs'
]

export function getToken () {
  return Cookies.getJSON(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token, { expires: 1 }) // add expiry 1 day
}

export function removeToken () {
  removeOnceLoggedOut.map(item => {
    Cookies.remove(item)
  })
  return Cookies.remove(TokenKey)
}
