import iconsHome from '@/components/icons/ios-home.vue'
import iconsPaper from '@/components/icons/ios-paper.vue'
import iconsTest from '@/components/icons/icon-test.vue'
import iconsDownload from '@/components/icons/ios-download.vue'
import iconsPrint from '@/components/icons/ios-print.vue'
import iconsMan from '@/components/icons/people-man.vue'
import iconsManOrange from '@/components/icons/people-man-orange.vue'
import iconsWman from '@/components/icons/people-woman.vue'
import iconsStaff from '@/components/icons/staff-logo.vue'
import iconsStoreOrange from '@/components/icons/ic-store-orange.vue'
import iconsStoreGreen from '@/components/icons/ic-store-green.vue'
import iconsIC from '@/components/icons/icon-ic.vue'
import iconsService from '@/components/icons/service.vue'
import iconsClass from '@/components/icons/class.vue'
import iconsVoucher from '@/components/icons/voucher.vue'
import iconsEcard from '@/components/icons/ecard.vue'
import iconsHandcard from '@/components/icons/hand-with-card.vue'
import iconsCashback from '@/components/icons/cashback'
import iconsStorePurple from '@/components/icons/icon_store_purple'
import iconsMoney from '@/components/icons/money-cashback'
import iconsAdminLogo from '@/components/icons/zenwel-admin-logo'
import iconsLoading from '@/components/icons/loading'
import iconsHotDeals from '@/components/icons/hotdeals'
import iconsDealServiceClass from '@/components/icons/deal-service-class'
import iconsDealVoucher from '@/components/icons/deal-voucher'
import iconsCreateBlog from '@/components/icons/create-blog'
import iconsArticle from '@/components/icons/article'
import iconsPeopleGreen from '@/components/icons/people_man_green'
import iconsManAdmin from '@/components/icons/man_admin'
import iconsZenwelBizLogo from '@/components/icons/zenwel-biz-logo'
import iconsZenwelBizLogo2 from '@/components/icons/zenwel-biz-logo2'
import iconsAdminSidebar from '@/components/icons/admin-logo'
import iconsReportAnalist from '@/components/icons/report-analist-logo'
import iconsDealsCoupon from '@/components/icons/deals-coupon'
import iconsBizDeals from '@/components/icons/biz-deals'
import iconsMarketplaceUser from '@/components/icons/marketplace_user'
import iconsAdminList from '@/components/icons/admin-list'
import iconsAdminLog from '@/components/icons/admin-log'
import iconsPlatformReport from '@/components/icons/platform-report-analysis'
import iconsBusinessLogo from '@/components/icons/business-logo'
import iconsSubscriptionLogo from '@/components/icons/subscription-logo'
import iconsMarketplaceLogo from '@/components/icons/marketplace-logo'
import icStaff from '@/components/icons/icon-staff'
import icService from '@/components/icons/icon-service'
import icProduct from '@/components/icons/icon-product'
import dotLoading from '@/components/icons/dot-loading'
import iconsChangepackage from '@/components/icons/icon-change-package'
import iconsExtend from '@/components/icons/icon_extend'
import iconslogout from '@/components/icons/ic_logout'
import icHome from '@/components/icons/ic_home'

export default {
  values: {
    home: {
      component: iconsHome
    },
    paper: {
      component: iconsPaper
    },
    test: {
      component: iconsTest
    },
    download: {
      component: iconsDownload
    },
    print: {
      component: iconsPrint
    },
    man: {
      component: iconsMan
    },
    woman: {
      component: iconsWman
    },
    staff: {
      component: iconsStaff
    },
    store_orange: {
      component: iconsStoreOrange
    },
    store_green: {
      component: iconsStoreGreen
    },
    ic: {
      component: iconsIC
    },
    manOrange: {
      component: iconsManOrange
    },
    service: {
      component: iconsService
    },
    class: {
      component: iconsClass
    },
    voucher: {
      component: iconsVoucher
    },
    ecard: {
      component: iconsEcard
    },
    hand_with_card: {
      component: iconsHandcard
    },
    cashback: {
      component: iconsCashback
    },
    store_purple: {
      component: iconsStorePurple
    },
    money_cashback: {
      component: iconsMoney
    },
    zenwel_admin: {
      component: iconsAdminLogo
    },
    loading: {
      component: iconsLoading
    },
    hot_deals: {
      component: iconsHotDeals
    },
    deal_service_class: {
      component: iconsDealServiceClass
    },
    deal_voucher: {
      component: iconsDealVoucher
    },
    create_blog: {
      component: iconsCreateBlog
    },
    article_icon: {
      component: iconsArticle
    },
    people_green: {
      component: iconsPeopleGreen
    },
    man_admin: {
      component: iconsManAdmin
    },
    zenwel_biz_logo: {
      component: iconsZenwelBizLogo
    },
    zenwel_biz_logo2: {
      component: iconsZenwelBizLogo2
    },
    admin_logo_sidebar: {
      component: iconsAdminSidebar
    },
    report_analist_logo: {
      component: iconsReportAnalist
    },
    deals_coupon: {
      component: iconsDealsCoupon
    },
    biz_deals: {
      component: iconsBizDeals
    },
    marketplace_user: {
      component: iconsMarketplaceUser
    },
    admin_list: {
      component: iconsAdminList
    },
    admin_log: {
      component: iconsAdminLog
    },
    platform_report: {
      component: iconsPlatformReport
    },
    business_logo: {
      component: iconsBusinessLogo
    },
    subscription_logo: {
      component: iconsSubscriptionLogo
    },
    marketplace_logo: {
      component: iconsMarketplaceLogo
    },
    icon_staff: {
      component: icStaff
    },
    icon_service: {
      component: icService
    },
    icon_product: {
      component: icProduct
    },
    dot_loading: {
      component: dotLoading
    },
    icon_change_package: {
      component: iconsChangepackage
    },
    icon_extend_package: {
      component: iconsExtend
    },
    icon_logout: {
      component: iconslogout
    },
    ic_home: {
      component: icHome
    }
  }
}
