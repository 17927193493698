import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import './icons' // icon
import '@/sass/main.scss'
import VueQuillEditor from 'vue-quill-editor'

// V-Charts
import VCharts from 'v-charts'

// Range Slider
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import undoIcon from 'quill/assets/icons/undo.svg'
import redoIcon from 'quill/assets/icons/redo.svg'
import sizeIcon from 'quill/assets/icons/size.svg'
import Quill from 'quill'
import ImageResize from '@taoqf/quill-image-resize-module'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// Gmap
import * as VueGoogleMaps from 'vue2-google-maps'

// V-viewer
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

// Loader Component
import './components/loader'

var DirectionAttribute = Quill.import('attributors/attribute/direction')
Quill.register(DirectionAttribute, true)

var AlignClass = Quill.import('attributors/class/align')
Quill.register(AlignClass, true)

var BackgroundClass = Quill.import('attributors/class/background')
Quill.register(BackgroundClass, true)

var ColorClass = Quill.import('attributors/class/color')
Quill.register(ColorClass, true)

var DirectionClass = Quill.import('attributors/class/direction')
Quill.register(DirectionClass, true)

var FontClass = Quill.import('attributors/class/font')
Quill.register(FontClass, true)

var SizeClass = Quill.import('attributors/class/size')
Quill.register(SizeClass, true)

var AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

var BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)

var ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)

var DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)

var FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)

var SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

Vue.component('phone-number-input', VuePhoneNumberInput)

// register new module quill editor
Quill.register('modules/imageResize', ImageResize)

// VUeQuill Editor
// var toolbarOptions = [
//   [
//     {
//       undo: undoicon
//     },
//     {
//       redo: redoicon
//     }
//   ],
//   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
//   ['blockquote', 'image', 'video'],

//   [{ 'header': 1 }, { 'header': 2 }], // custom button values
//   [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//   [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
//   [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
//   [{ 'direction': 'rtl' }], // text direction

//   [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
//   [{ 'font': [] }],
//   [{ 'align': [] }],

//   ['clean']
// ]
var icons = Quill.import('ui/icons')
icons['undo'] = undoIcon
icons['redo'] = redoIcon
icons['size'] = sizeIcon

var toolbarOptions = {
  container: [
    [
      {
        undo: undoIcon
      },
      {
        redo: redoIcon
      }
    ],
    [{ 'size': [ 'small', false, 'large', 'huge' ] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    // ['blockquote', 'image', 'video'],
    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
    ['image', 'video'],
    ['link'],
    [{ 'header': 1 }, { 'header': 2 }], // custom button values
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    // [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
    // [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
    // [{ 'direction': 'rtl' }], // text direction
    // [{ 'font': [] }],
    // ['clean']
  ],
  handlers: {
    redo () {
      // console.log('redo', VueQuillEditor)
      this.editor.history.redo()
    },
    undo () {
      this.editor.history.undo()
    }
  }
}

Vue.use(VueQuillEditor, {
  theme: 'snow',
  modules: {
    imageResize: {
      displaySize: true
    },
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false
    },
    toolbar: toolbarOptions
  }
})

// Gmap
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBIHZq9rBtwG9PFp575ZsPJebteE6T-nLA',
    libraries: 'places'
  },
  installComponents: true
})

// V-Charts
Vue.use(VCharts)

// V-viewer
Vue.use(Viewer, {
  defaultOptions: {
    scalable: false
  }
})

// Range Slider
Vue.use(VueRangeSlider)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
