export default {
  dataTable: {
    ariaLabel: {
      sortNone: 'Sort None'
    }
  },

  add_admin: 'Add Admin',
  activity: 'Activity',
  current_activity: 'Current Activity',
  see_all_activity: 'See All Activity',
  admin: 'Admin',
  categories: 'Categories',
  download: 'Download',
  print: 'Print',
  add: 'Add',
  new_admin: 'New Admin',
  name: 'Name',
  username: 'Username',
  email: 'Email',
  password: 'Password',
  phone_number: 'Phone Number',
  level: 'Level',
  country: 'Country',
  city: 'City',
  upload: 'Upload',
  photo: 'Photo',
  save: 'Save',
  admin_name: 'Admin Name',
  last_activity: 'Last Activity',
  action: 'Action',
  edit_admin: 'Edit Admin',
  total_activity: 'Total Activity',
  joined_date: 'Joined Date',
  created_by: 'Created by',
  close: 'Close',
  update: 'Update',
  activities: 'Activities',
  activity_detail: 'Activity Detail',
  noDataText: 'No Data Text',
  over_view: 'Overview',
  create_newdeals: 'Create New Hot Deals',
  service: 'Service',
  class: 'Class',
  service_class_voucher: 'Service and Class Voucher',
  new_voucher: 'New Voucher',
  service_voucher: 'Service Voucher',
  class_voucher: 'Class Voucher',
  voucher: 'Voucher',
  voucher_used: 'Voucher Used',
  merchant_name: 'Merchant Name',
  item: 'Item',
  discount: 'Discount',
  revenue: 'Revenue',
  step_1deals: 'Step 1: Select Merchant',
  step_2deals: 'Step 2: Select Class atau Service',
  step_3deals: 'Step 3: Deals Detail',
  search: 'Search',
  next: 'Next',
  change: 'Change',
  selected: 'Selected',
  minimum_purchase: 'Minimum Purchase',
  voucher_limit: 'Voucher Limit',
  max_usage_customer: 'Max Usage per Customer',
  platform_applied: 'Platform Applied',
  expiry_period: 'Expiry Period',
  day: 'Day',
  priority_level: 'Priority Level',
  location_applied: 'Location Applied',
  create: 'Create',
  edit_hot_promo: 'Edit Hot Promo',
  discounted_price: 'Discounted Price',
  normal_price: 'Normal Price',
  date_period: 'Date Period',
  location: 'Location',
  create_newecard: 'Create New e-Card',
  merchant_with_ecard: 'Merchant with e-Card',
  ecard_used: 'e-Card Used',
  no_activity: 'No Activity',
  ecard_amount: 'e-Card Amount',
  expiry_date: 'Expiry Date',
  step_2ecard: 'Step 2: Detail e-Card',
  other_ecard: 'Other e-Card',
  ecard_background: 'e-Card Background Color',
  day_limit: 'Day Limit',
  preview: 'Preview',
  available_until: 'Available Until',
  amount: 'Amount',
  price: 'Price',
  date: 'Date',
  edit: 'Edit',
  ecard: 'e-Card',
  detail: 'Detail',
  delete: 'Delete',
  delete_ecard: 'Delete this e-Card',
  create_newcashback: 'Create New Cashback',
  merchant_with_cashback: 'Merchant with Cashback',
  cashback_used: 'Cashback Used',
  cashback: 'Cashback',
  cashback_amount: 'Cashback Amount',
  step_2cashback: 'Step 2: Detail Cashback',
  cashback_limit: 'Cashback Limit',
  set_for_newmember: 'Set For New Member',
  cashback_type: 'Cashback Type',
  set_for_first_order: 'Set for First Time Order',
  min_purchase: 'Min. Purchase',
  are_you_sure_delete: 'Are you sure you want to delete ',
  create_article: 'Create New Article',
  articles: 'Articles',
  new_articles: 'New Articles',
  tags_categories: 'Tag/Categories',
  basic_merchant: 'Basic Merchant',
  visitor: 'Visitor',
  from_indonesia: 'From Indonesia',
  outside_indonesia: 'Outside Indonesia',
  title: 'Title',
  published_on: 'Published On',
  tags: 'Tags',
  cancel: 'Cancel',
  del_account: 'This account and all activities will be deleted from the database',
  del_activity: 'This activity will be deleted from the account and database',
  total: 'Total',
  merchant: 'Merchant',
  new_merchant: 'New Merchant',
  merchant_list: 'Merchant List',
  hourly_activity: 'Hourly Activity',
  staff: 'Staff',
  platform: 'Platform',
  time_joined: 'Time Joinded',
  inactive_merchant: 'Inactive Merchant',
  top_longest_inactive: 'Top Longest Inactive',
  merchant_activity: 'Merchant Activity',
  member_status: 'Member Status',
  del_article: 'This article will be deleted from the database',
  article: 'Article',
  publish: 'Publish',
  draft: 'Draft',
  last_modified: 'Last Modified',
  words: 'Words',
  del_ecard: 'This E-Card will be deleted from the database',
  bussiness: 'Bussiness',
  package: 'Package',
  subscription: 'Subscription',
  marketplace: 'Marketplace',
  biz_deal: 'Biz Deals',
  see_price_detail: 'See price detail',
  select: 'Select',
  payment_method: 'Payment Method',
  input: 'Input',
  reference_code: 'Reference Code',
  apply: 'Apply',
  payment: 'Payment',
  cancel_transaction: 'Cancel Transaction',
  cancel_option: 'Cancel Option',
  package_to_change: 'Package to Change',
  package_expired_on: 'Package will expired on :',
  automatic_downgrade_note: 'The package will be automatic downgraded to free',
  merchant_will_get_refund: 'Merchant will get a refund :',
  business_type: 'Business Type',
  your_package_by_extend: 'Your Package has been extended',
  company_city: 'Company City',
  owner_phone: 'Owner phone',
  marketplace_status: 'Marketplace Status',
  published: 'Published',
  unpublished: 'Unpublished',
  manage: 'Manage',
  payment_history: 'Payment History',
  downgrade_note: 'You are about to downgrade the package. Your change will be activated after',
  price_detail: 'Price Detail'
}
