<template>
    <v-menu
      v-model="visible"
      :close-on-content-click="false"
      :max-width="250"
      offset-y
    >
      <template v-if="!btnStdr" v-slot:activator="{ on }">
        <v-btn v-if="table" icon v-on="on">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
        <v-btn v-else v-on="on" class="mx-2" depressed fab dark small color="#FDF1EF" style="border: 1px solid #E74C3C;" >
            <v-icon color="#E74C3C">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <template v-else v-slot:activator="{ on }">
        <v-btn v-on="on" width="136px" class="text-capitalize" color="#E74C3C" outlined style="background: #FDF1EF"><span class="subtitle-1">{{ lang.t('$vuetify.delete') }} {{ lang.t('$vuetify.' + btnTitle) }}</span></v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{ name }}</v-list-item-title>
              <v-list-item-subtitle class="caption">{{ lang.t('$vuetify.are_you_sure_delete') }} ?</v-list-item-subtitle>
              <span class="caption">{{ lang.t(message) }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="visible = false">{{ lang.t('$vuetify.cancel') }}</v-btn>
          <v-btn color="primary" text @click="handleConfirm">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Name'
    },
    table: {
      type: Boolean,
      default: false
    },
    btnTitle: {
      type: String,
      default: ''
    },
    btnStdr: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: '$vuetify.del_account'
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  data: () => ({
    fav: true,
    visible: false,
    message: false,
    hints: true
  }),

  methods: {
    handleConfirm () {
      this.visible = false
      this.$emit('confirm')
    }
  }
}
</script>
