import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Layout.vue'),
    redirect: '/dashboard',
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import('@/views/dashboard/Index.vue')
      },
      {
        path: 'zenwel-biz',
        name: 'Biz',
        component: () => import('@/views/zenwelbiz/index.vue'),
        redirect: '/zenwel-biz/package',
        children: [
          {
            name: 'Package',
            path: 'package',
            component: () => import('@/views/zenwelbiz/package/index.vue')
          },
          {
            name: 'Business',
            path: 'business',
            component: () => import('@/views/zenwelbiz/bussiness/bussiness.vue')
          },
          {
            name: 'Subscription',
            path: 'subscription',
            component: () => import('@/views/zenwelbiz/subscription/index.vue')
          },
          {
            name: 'Marketplace',
            path: 'marketplace',
            component: () => import('@/views/zenwelbiz/marketplace/index.vue')
          },
          {
            name: 'Biz Deals',
            path: 'biz-deals',
            component: () => import('@/views/zenwelbiz/bizdeal/index.vue')
          }
        ]
      },
      {
        name: 'Business Detail',
        path: 'zenwel-biz/business/:id',
        component: () => import('@/views/zenwelbiz/bussiness/merchant/index.vue'),
        localization_key: 'business_detail'
      },
      {
        path: 'deals',
        name: 'deals',
        component: () => import('@/views/deals/index.vue'),
        redirect: '/deals/hotdeals',
        children: [
          {
            name: 'Hot Deals Details',
            path: ':id',
            component: () => import('@/views/deals/hotdeals/_detail.vue'),
            localization_key: 'hotdeals_detail'
          },
          {
            name: 'Ecard Detail',
            path: 'ecard/:id',
            component: () => import('@/views/deals/ecard/_detail.vue'),
            localization_key: 'ecard_detail'
          },
          {
            name: 'Cashback Detail',
            path: 'cashback/:id',
            component: () => import('@/views/deals/cashback/_detail.vue'),
            localization_key: 'cashback_detail'
          }
        ]
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import('@/views/admin/index.vue')
      },
      {
        name: 'Admin Detail',
        path: 'admin/:id',
        component: () => import('@/views/admin/detail.vue'),
        localization_key: 'admin_detail'
      },
      {
        path: 'marketplace-user',
        name: 'Marketplace User',
        component: () => import('@/views/marketplace-user/index.vue')
      },
      {
        path: 'report',
        name: 'report',
        component: () => import('@/views/report/index.vue'),
        redirect: '/report/report_merchant',
        children: [
          {
            name: 'Report Merchant',
            path: 'report_merchant',
            component: () => import('@/views/report/merchant/index.vue'),
            localization_key: 'report_merchant'
          },
          {
            name: 'Merchant Detail',
            path: 'report_merchant/:id',
            component: () => import('@/views/report/merchant/detail/detail-merchant.vue'),
            localization_key: 'merchant_detail'
          },
          {
            name: 'Location Detail',
            path: 'report_merchant/location/:id',
            component: () => import('@/views/report/merchant/detail/_dialog-location-detail.vue'),
            localization_key: 'location_detail'
          },
          {
            name: 'Service Detail',
            path: 'report_merchant/sales/:id',
            component: () => import('@/views/report/merchant/detail/_sales-service-detail.vue'),
            localization_key: 'service_detail'
          },
          {
            name: 'Report Member',
            path: 'report_member',
            component: () => import('@/views/report/member/index.vue'),
            localization_key: 'report_member'
          },
          {
            name: 'Member Detail',
            path: 'report_member/:id',
            component: () => import('@/views/report/member/detail-member.vue'),
            localization_key: 'member_detail'
          }
        ]
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/About.vue')
      }
      // {
      //   path: 'blog',
      //   name: 'blog',
      //   component: () => import('@/views/Layout.vue'),
      //   redirect: '/blog/detail',
      //   children: [
      //     {
      //       name: 'Blog',
      //       path: 'detail',
      //       component: () => import('@/views/blog/index.vue')
      //     },
      //     {
      //       name: 'New Article',
      //       path: 'article',
      //       component: () => import('@/views/blog/create.vue')
      //     },
      //     {
      //       name: 'Edit Article',
      //       path: 'article/:id',
      //       component: () => import('@/views/blog/preview.vue')
      //     }
      //   ]
      // },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
