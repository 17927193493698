import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import id from '@/locale/id'
import en from '@/locale/en'
// import '../icons' // icon
import icons from '@/components/icons/icons'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { id, en },
    current: 'en'
  },
  icons: icons
})
