import axios from 'axios'
// import { Message } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // api
  baseURL: 'https://zenwelinternal.indociti.com/api/admin/v1/', // api
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      if (store.getters.token) {
        config.headers['Authorization'] = 'Bearer ' + token.access_token
      }
    }
    //  else {
    //   config.headers['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijk1YjMyZjE2ZmRlODAzYTQ2NzUzN2E1MTU5NGQ1YTllYjgxNjlkZDE4ZmFhOWQ3NWVlZjgxZDhjNWFlMzU4OGVlNmUwZmY0MWNmZjYwNGUyIn0.eyJhdWQiOiIyIiwianRpIjoiOTViMzJmMTZmZGU4MDNhNDY3NTM3YTUxNTk0ZDVhOWViODE2OWRkMThmYWE5ZDc1ZWVmODFkOGM1YWUzNTg4ZWU2ZTBmZjQxY2ZmNjA0ZTIiLCJpYXQiOjE1NjEwMTI5NDIsIm5iZiI6MTU2MTAxMjk0MiwiZXhwIjoxODc2NjMyMTQyLCJzdWIiOiIiLCJzY29wZXMiOltdfQ.GzZ3pVl6A4Wcqqt_mnTnTnvhMrx57VWZmTQZDtNZ6h-aKyLtm5X3neEHzsSMoP0IRTALsHbwA7TJqURR4JUPObWmkFDKQKp4fMAgvxUc_uqZQME3VMayomo1vj_79lC1gbxj9TFp3TtAZXqkIbnj7vt4Yj7yMuQ9KQXNo5LE5iYpoRtksn9uKEBCxEVqUcDdHRwJc7zeCELAo1PPvwUzoxPkSu8T3GFBXG2rTrNhqt7hVrFQNlMSAoDQB997A6Vbew1TXPnJSflCcr65hrdAAqIKWkXb7lzsYYQVaXXflgK6gp4HdaWJqgqgFPevlQyQzIzZ4u7OFXmvwOp4K6Wip4Hah7M4OmLeJ4VxiC6iPjTOV0GfSRivlz_2vOFEYnMwrosooR5J4lm7UgdKBDjreb84qSoE6mDZTfvZo1PrzOybsxcXg9c1N5JEkHo99o3uh3F9CHgu3h_zChoITKWU2zG6MJCh59J4QXEAbtGyz670jSDRX_6hZHLKDBNZ1R764SQxZYtFXTlxKxOpzSl52LJvdedvClTa1vMnwOpa5t4ya6hmlOZQqPINKMN2pIxh-pPQ7pTGPBHUZ_SIqVt8zsasfl9rT-VyShXecHiii18wIcvj7T2JCxD81lI_T0RRrp4hlx-F6EtWbUIfcOHMLh5a-tKRmidKmHzWAwKaQfs'
    // }
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => response,
  error => {
    console.log('Hello Error', error.response.data.error.error)
    const errorError = error.response.data.error.error
    let errArr = errorError
    let string = errArr

    if (typeof errorError !== 'string') {
      string = ''
      errArr = Object.values(errorError)
      for (let index = 0; index < errArr.length; index++) {
        string += '- ' + errArr[index]
      }
    }

    let statusCode = '500'
    let message = 'Connection timeout, please check your internet connection'
    if (error.response) {
      statusCode = error.response.data.error.status_code
      message = error.response.data.error.message
    }

    // console.log(router)

    // if (statusCode === 401) {
    //   router.push('/login')
    // }

    // return Promise.reject({statusCode,message,string})
    let err = {
      statusCode: statusCode,
      message: message,
      string: string
    }
    return Promise.reject(err)
  }
)

export default service
